/* ./routes/Home.module.css */

/* Main container styles */
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
}

/* Header styles */
.homepageHeader {
    position: relative;
    background: linear-gradient(145deg, #035ebf, #0492ff);
    color: #0e0b0b;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
}

.headerBox {
    z-index: 1;
}

.headerBox::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, #78aaff, #87cefa);
    transform: skewY(-1.5deg);
}

/* Heading styles */
h1,
h2,
h3 {
    color: #000000;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0;
}

h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #f0efe4;
}

h2 {
    font-size: 3rem;
    font-weight: 600;
    color: #080800;
}

h3 {
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
}

/* Glossy card styles */
.glossy-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
    margin: 15px 0;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    width: 100%;
}

.glossy-card:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .homepage {
        padding: 15px;
    }

    .homepageHeader {
        padding: 20px;
        margin: 0 auto 20px;
    }

    .headerBox::before {
        height: 10px;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1rem;
    }

    .homepage-companies,
    .glossy-card {
        margin: 15px 0;
    }
}

@media (max-width: 480px) {
    .homepage {
        padding: 10px;
    }

    .homepageHeader {
        padding: 15px;
        margin: 0 auto 15px;
    }

    .headerBox::before {
        height: 8px;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 0.875rem;
    }

    .homepage-companies,
    .glossy-card {
        margin: 10px 0;
    }
}