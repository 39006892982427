/* ./components/Footer.css */

.footer {
    background: linear-gradient(180deg, rgba(4, 109, 166, 1), rgba(0, 123, 255, 1));
    color: #ffffff;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 10px;
    /* Reduced padding for better fit on mobile */
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Reduced shadow size */
    border-top: 4px solid #f5c508;
    /* Reduced top border size */
}

.footer-content {
    display: flex;
    justify-content: space-between;
    /* Changed to space-between for better fit */
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    /* Ensured it fits within viewport */
    margin: 0 auto;
    padding: 10px;
    /* Reduced padding for mobile */
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    /* Reduced border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Reduced shadow size */
    backdrop-filter: blur(8px);
    /* Adjusted blur effect */
}

.footer-section {
    margin: 10px;
    /* Reduced margin for mobile */
    min-width: 200px;
    /* Adjusted minimum width */
    max-width: 100%;
    /* Ensure sections don't exceed container width */
}

.footer-section h3 {
    margin-bottom: 10px;
    /* Reduced margin for better fit */
    font-size: 1.8em;
    /* Adjusted font size for consistency */
    color: #f5c508;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    /* Reduced text shadow size */
}

.footer-section p {
    margin: 6px 0;
    /* Adjusted margin */
    font-size: 1.4em;
    /* Adjusted font size */
    color: #ffffff;
}

.footer-section a {
    color: #f5c508;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease, letter-spacing 0.3s ease;
}

.footer-section a:hover {
    color: #ffeb3b;
    transform: scale(1.1);
    /* Adjusted scale for better fit */
    letter-spacing: 0.5px;
    /* Adjusted letter-spacing */
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin: 10px 0;
        /* Adjusted margin for better spacing */
        text-align: center;
    }
}

@media (max-width: 480px) {
    .footer-section h3 {
        font-size: 1.5em;
        /* Further adjusted font size */
    }

    .footer-section p {
        font-size: 1.2em;
        /* Further adjusted font size */
    }
}