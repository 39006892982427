\\\.feature-header {
    font-family: 'Lato', sans-serif;
    text-align: center;
    padding: 20px;
    color: #261f1f;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 100px;
}

.feature-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.feature-item {
    width: calc(25% - 20px);
    margin: 10px;
    position: relative;
    transition: transform 0.3s ease;
}

.feature-item .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.feature-item:hover .overlay {
    opacity: 1;
}

.feature-item a {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.feature-img {
    width: calc(100% - 10px);
    height: 250px;
    margin: 5px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover .feature-img {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
}

.feature-item:hover {
    transform: scale(1.05);
}

@media (max-width: 1200px) {
    .feature-item {
        flex: 1 1 calc(33.33% - 20px);
    }
}

@media (max-width: 900px) {
    .feature-item {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .feature-item {
        flex: 1 1 calc(100% - 20px);
    }

    .feature-header {
        font-size: 1.5rem;
    }
}