.slider-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 610px;
    margin: auto;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #ffffff, #f7f7f7);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    cursor: pointer;
    /* Add this line for cursor change */
}

.slider-container.transition-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.slider-container:hover {
    transform: scale(1.03);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4);
}

.slide-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    height: 600px;
    padding: 20px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 15px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-wrapper.transition-out .slide {
    transform: scale(0.95);
    opacity: 0;
}

.slide-wrapper.transition-in .slide {
    transform: scale(1);
    opacity: 1;
}

.text-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prev,
.next {
    position: absolute;
    top: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    color: #333;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.prev:hover,
.next:hover {
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.prev {
    left: 15px;
}

.next {
    right: 15px;
}

.dots {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: rgba(190, 188, 188, 0.7);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s;
    cursor: pointer;
}

.dot.active {
    background-color: rgb(144, 137, 137);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dot:hover {
    background-color: rgb(89, 86, 86);
}

@media (max-width: 780px) {
    .slider-container {
        height: auto;
        width: 85%;
        /* Adjust height for smaller screens */
        box-shadow: none;
    }

    .slide {
        height: 100vw;
        width: 85%;
        margin: 0 auto;
        /* Center the slide */
        padding: 10px;
    }

    .text-overlay {
        bottom: 10px;
        left: 10px;
        padding: 5px 10px;
        font-size: 14px;
    }

    .prev,
    .next {
        width: 30px;
        height: 30px;
        font-size: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
    }

    .prev {
        left: 20px;
        /* Increased spacing from left edge */
    }

    .next {
        right: 20px;
        /* Increased spacing from right edge */
    }

    .dots {
        bottom: 5px;
    }

    .dot {
        height: 8px;
        width: 8px;
        margin: 0 3px;
    }
}