.aboutUsContainer {
    display: flex;
    flex-direction: row;
    /* Arrange elements horizontally by default */
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.textContainer,
.photoContainer {
    width: 45%;
    /* Default width for larger screens */
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.photoContainer h1{
    font-size: 3rem;
        /* Adjust size as needed */
        font-weight: bold;
        color: #000;
        /* Base color */
        background: linear-gradient(135deg, #333 0%, #000 100%);
        /* Gradient background for more depth */
        -webkit-background-clip: text;
        /* Clipping to apply gradient to text */
        -webkit-text-fill-color: transparent;
        /* Make text color transparent to show gradient */
        text-shadow:
            2px 2px 4px rgba(0, 0, 0, 0.5),
            /* Subtle shadow for depth */
            0 0 10px rgba(255, 255, 255, 0.8);
        /* Glow effect to make it pop */
        margin: 0;
        padding: 0;
}

.photoContainer img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.photoContainer img:hover {
    transform: scale(1.03);
}

.textContainer h2 {
    font-size: 3rem;
    /* Default font size for larger screens */
    font-family: 'Arial', sans-serif;
    color: #0b04e4;
    margin-bottom: 15px;
    text-shadow: 1px 1px 2px rgba(11, 157, 255, 0.844);
    transition: color 0.3s ease-in-out;
}

.textContainer h2:hover {
    color: #127dce;
}

.textContainer p {
    font-size: 1.4rem;
    /* Default font size for larger screens */
    line-height: 1.8;
    font-family: 'Roboto', sans-serif;
}

@media (max-width: 767px) {
    .aboutUsContainer {
        flex-direction: column;
        /* Stack elements vertically on small screens */
        align-items: center;
        padding: 20px;
    }

    .textContainer,
    .photoContainer {
        width: 100%;
        /* Full width on small screens */
        padding: 10px;
        margin-bottom: 20px;
        /* Space between stacked elements */
    }

    .textContainer h2 {
        font-size: 2rem;
        /* Adjusted font size for smaller screens */
        margin-bottom: 10px;
    }

    .textContainer p {
        font-size: 1.2rem;
        /* Adjusted font size for smaller screens */
    }
}